// AppRoutes.js

import React from 'react';
import {Routes, Route} from 'react-router-dom';
import PlaygroundComponent from "./components/playground/PlaygroundComponent";
import PlaygroundDetailComponent from "./components/playground/detail/PlaygroundDetailComponent";
import NewPlayGroundComponent from "./components/playground/create/NewPlayGroundComponent";
import BlogsComponent from "./components/blog/BlogsComponent";
import BlogDetailComponent from "./components/blog/detail/BlogDetailComponent";
import SimpleBlogComponent from "./components/blog/create/simple/SimpleBlogComponent";
import SelectHowCreateNewBlogComponent from "./components/blog/create/SelectHowCreateNewBlogComponent";
import AccountComponent from "./components/account/AccountComponent";
import LoginComponent from "./components/login/LoginComponent";
import ChangePasswordComponent from "./components/account/ChangePasswordComponent";
import RequestChangePassword from "./components/login/RequestChangePassword";
import NotFoundComponent from "./components/utils/NotFoundComponent";
import EmailVerificationComponent from "./components/verify/EmailVerificationComponent";
import PrivacyComponent from "./components/utils/PrivacyComponent";
import App from "./components/App";
import {API_BASE_URL} from "./config";

// Data fetching functions for server-side rendering
async function fetchUserData(params) {
    const {username} = params;
    try {
        const response = await fetch(`${API_BASE_URL}/api/v1/user/get/${username}`);
        if (!response.ok) {
            throw new Error('Failed to fetch blog data');
        }
        const data = await response.json();
        return {userData: data};
    } catch (error) {
        console.error('Error fetching blog data:', error);
        return {userData: null};
    }
}

async function fetchExploreData() {
    try {
        const response = await fetch(`${API_BASE_URL}/api/v1/playground/get/by/trending?page=0&size=15`);
        if (!response.ok) {
            throw new Error('Failed to fetch explore data');
        }
        const data = await response.json();
        return {exploreData: data};
    } catch (error) {
        console.error('Error fetching explore data:', error);
        return {exploreData: []};
    }
}

async function fetchExploreDetailData(params) {
    const { username, slug } = params;
    try {
        if (!username || !slug) {
            throw new Error('Invalid username or slug');
        }
        const response = await fetch(`${API_BASE_URL}/api/v1/playground/get/${username}/${slug}`);
        if (!response.ok) {
            throw new Error('Failed to fetch explore data');
        }
        const data = await response.json();
        return { exploreData: data };
    } catch (error) {
        console.error('Error fetching explore data:', error);
        return { exploreData: null };
    }
}



async function fetchBlogDetailData(params) {
    const {username, slug} = params;
    try {
        const response = await fetch(`${API_BASE_URL}/api/v1/blog/get/${username}/${slug}`);
        if (!response.ok) {
            throw new Error('Failed to fetch blog data');
        }
        const data = await response.json();
        return {blogData: data};
    } catch (error) {
        console.error('Error fetching blog data:', error);
        return {blogData: null};
    }
}

async function fetchBlogsBySort(params) {
    const {sortByParams} = params;
    const sortBy = sortByParams || 'all'; // Default to 'all' if undefined
    try {
        const response = await fetch(`${API_BASE_URL}/api/v1/blog/get/by/${sortBy}?page=0&size=5`);
        if (!response.ok) {
            throw new Error('Failed to fetch blogs by sort');
        }
        const data = await response.json();
        return {blogsData: data};
    } catch (error) {
        console.error('Error fetching blogs by sort:', error);
        return {blogsData: []};
    }
}

// Define routes with potential loadData functions
const routes = [
    {
        path: '/',
        element: <App/>,
        loadData: null,
    },
    // Explore routes
    {
        path: '/explore',
        element: <PlaygroundComponent/>,
        loadData: fetchExploreData,
    },
    {
        path: '/:username/explore/:slug',
        element: <PlaygroundDetailComponent/>,
        loadData: fetchExploreDetailData, // Add loadData if needed
    },
    {
        path: '/explore/:tagName',
        element: <PlaygroundComponent/>,
        loadData: null,
    },
    {
        path: '/explore/new',
        element: <NewPlayGroundComponent/>,
        loadData: null,
    },
    {
        path: '/:username/edit/explore/:slug',
        element: <NewPlayGroundComponent/>,
        loadData: null,
    },
    // Blog routes
    {
        path: '/blogs',
        element: <BlogsComponent/>,
        loadData: fetchBlogsBySort, // Assuming it fetches all blogs
    },
    {
        path: '/blogs/:sortByParams',
        element: <BlogsComponent/>,
        loadData: fetchBlogsBySort,
    },
    {
        path: '/:username/blog/:slug',
        element: <BlogDetailComponent/>,
        loadData: fetchBlogDetailData,
    },
    {
        path: '/blog/new',
        element: <SimpleBlogComponent/>,
        loadData: null,
    },
    {
        path: '/blog/types',
        element: <SelectHowCreateNewBlogComponent/>,
        loadData: null,
    },
    // Edit Blog
    {
        path: '/:username/edit/blog/:slug',
        element: <SimpleBlogComponent/>,
        loadData: null,
    },
    // Account Settings
    {
        path: '/user/:username',
        element: <AccountComponent/>,
        loadData: fetchUserData,
    },
    // Login page
    {
        path: '/login',
        element: <LoginComponent/>,
        loadData: null,
    },
    {
        path: '/change-password/:codeVerify',
        element: <ChangePasswordComponent/>,
        loadData: null,
    },
    {
        path: '/forgot-password',
        element: <RequestChangePassword/>,
        loadData: null,
    },
    // Verification
    {
        path: '/verify/:codeVerify',
        element: <EmailVerificationComponent/>,
        loadData: null,
    },
    // Privacy
    {
        path: '/privacy',
        element: <PrivacyComponent/>,
        loadData: null,
    },
    // Catch-all route
    {
        path: '/404',
        element: <NotFoundComponent/>,
        loadData: null,
    },
    {
        path: '*',
        element: <NotFoundComponent/>,
        loadData: null,
    },
];

// The AppRoutes component that passes initialData to components
export function AppRoutes({ initialData = {} }) {
    return (
        <Routes>
            {routes.map((route, index) => (
                <Route
                    key={index}
                    path={route.path}
                    element={React.cloneElement(route.element, {
                        initialData: initialData[route.path] || {},
                    })}
                />
            ))}
        </Routes>
    );
}

export { routes };