import React, {useEffect, useState, useCallback, Fragment} from 'react';
import './BlogDetailComponent.css'
import MenuComponent from "../../utils/menu/MenuComponent";
import FooterComponent from "../../utils/footer/FooterComponent";
import {Link, useNavigate, useParams} from "react-router-dom";
import {API_BASE_URL} from "../../../config";
import Spinner from "../../utils/sipnner/Spinner";
import ShineBorder from "../../../ui/shine-border";
import {CiBookmark, CiBookmarkCheck} from "react-icons/ci";
import {getRelativeTime} from "../../utils/dateUtils";
import {BiSolidDislike, BiSolidLike} from "react-icons/bi";
import {AiOutlineDislike, AiOutlineLike} from "react-icons/ai";
import CommentBlogDetail from "../item/CommentBlogDetail";
import {MdOutlineEditNote} from "react-icons/md";
import ImageComponent from "../../utils/ImageComponent";
import {useCookies} from "../../../cookieContext";
import StructuredData, {truncateDescription} from "../../utils/StructuredData";
import createDOMPurify from "dompurify";

function BlogDetailComponent({initialData}) {
    const navigate = useNavigate();
    const {slug, username} = useParams();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [blog, setBlog] = useState(initialData.blogData || null);
    const [blogTags, setBlogTags] = useState([]);
    const [blogLikes, setBlogLikes] = useState(0);
    const [followers, setFollowers] = useState(0);
    const [isFollowing, setIsFollowing] = useState(false);
    const [isSaved, setIsSaved] = useState(false);
    const [isUserLiked, setIsUserLiked] = useState(false);
    const [isUserDisliked, setIsUserDisliked] = useState(false);

    const {cookies} = useCookies();
    const emailLocal = cookies.email;
    const tokenLocal = cookies.token;

    const handleDeleteLikeBlog = async () => {
        if (!emailLocal && !tokenLocal) {
            navigate('/login');
        }
        try {
            const response = await fetch(`${API_BASE_URL}/api/v1/like-blog/edit/delete/${emailLocal}/${blog.id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${tokenLocal}`
                }
            })
            if (!response.ok) {
                throw new Error(`Error deleting like blog: ${response.status}`);
            }
            setBlogLikes(prevState => prevState - 1);
            setIsUserDisliked(false);
            setIsUserLiked(false);
        } catch (err) {
            console.error(err);
            setError('Failed to delete the like blog. Please try again later.');
        }
    }

    const handleLikeBlog = async (status) => {
        if (!emailLocal && !tokenLocal) {
            navigate('/login');
        }
        try {
            const response = await fetch(`${API_BASE_URL}/api/v1/like-blog/edit/add`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${tokenLocal}`
                },
                body: JSON.stringify({
                    "blogId": blog.id,
                    "email": emailLocal,
                    "likeStatus": status
                })
            })
            if (!response.ok) {
                throw new Error(`Error liking blog: ${response.status}`);
            }
            if (status) {
                setBlogLikes(prevState => prevState + 1);
                setIsUserLiked(true);
                setIsUserDisliked(false);
            } else {
                setBlogLikes(prevState => prevState - 1);
                setIsUserLiked(false);
                setIsUserDisliked(true);
            }
        } catch (err) {
            console.error(err);
            setError('Failed to like the blog. Please try again later.');
        }
    }

    const handleDeleteSavedBlog = async () => {
        if (!emailLocal && !tokenLocal) {
            navigate('/login');
        }
        try {
            const response = await fetch(`${API_BASE_URL}/api/v1/save-blog/edit/delete/${emailLocal}/${blog.id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${tokenLocal}`
                }
            })
            if (!response.ok) {
                throw new Error(`Error deleting saved blog: ${response.status}`);
            }
            setIsSaved(false);
        } catch (err) {
            console.error(err);
            setError('Failed to delete the saved blog. Please try again later.');
        }
    }

    const handleSaveBlog = async () => {
        if (!emailLocal && !tokenLocal) {
            navigate('/login');
        }
        try {
            const response = await fetch(`${API_BASE_URL}/api/v1/save-blog/edit/add/${emailLocal}/${blog.id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${tokenLocal}`
                }
            })
            if (!response.ok) {
                throw new Error(`Error saving blog: ${response.status}`);
            }
            setIsSaved(true);
        } catch (err) {
            console.error(err);
            setError('Failed to save the blog. Please try again later.');
        }
    }

    const handleDeleteFollow = async () => {
        if (!blog || !blog.user || !blog.user.username) {
            setError('Blog or user information is missing.');
            return;
        }
        try {
            const response = await fetch(`${API_BASE_URL}/api/v1/follow/edit/delete/${blog.user.username}/${emailLocal}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${tokenLocal}`
                }
            });
            if (!response.ok) {
                throw new Error(`Error deleting follow: ${response.status}`);
            }
            setFollowers(prevState => prevState - 1);
            setIsFollowing(false);
            setError(null); // Clear any previous errors
        } catch (err) {
            console.error(err);
            setError('Failed to unfollow the user. Please try again later.');
        }
    }; // Add this closing brace

    const handleFollow = async () => {
        if (!blog || !blog.user || !blog.user.username) {
            setError('Blog or user information is missing.');
            return;
        }

        if (!tokenLocal || !emailLocal) {
            setError('User authentication details are missing.');
            navigate('/login');
        }

        const request = {
            "follower": blog.user.email,
            "followed": emailLocal
        };

        try {
            const response = await fetch(`${API_BASE_URL}/api/v1/follow/edit/add`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${tokenLocal}`
                },
                body: JSON.stringify(request)
            });

            if (!response.ok) {
                throw new Error(`Error following user: ${response.status}`);
            }

            setFollowers(prevState => prevState + 1);
            setIsFollowing(true);
            setError(null); // Clear any previous errors
        } catch (err) {
            console.error(err);
            setError('Failed to follow the user. Please try again later.');
        }
    };

    const fetchData = useCallback(async (url, errorMessage) => {
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error(`${errorMessage}: ${response.status}`);
        }
        return response.json();
    }, []);

    const updateViewCount = useCallback(async () => {
        if (blog) {
            await fetchData(`${API_BASE_URL}/api/v1/blog/get/view/${blog.id}`, 'Error updating view count');
        }
    }, [blog, fetchData]);

    const fetchBlogDetail = useCallback(async () => {
        try {
            const data = await fetchData(`${API_BASE_URL}/api/v1/blog/get/${username}/${slug}`, 'Error fetching blog');
            if (data.status === "DELETED") {
                navigate('/404');
            }
            setBlog(data);
        } catch (err) {
            navigate('/404');
            setError(err.message);
        }
    }, [username, slug, fetchData]);

    const fetchBlogTags = useCallback(async () => {
        if (blog) {
            try {
                const data = await fetchData(`${API_BASE_URL}/api/v1/blog-tag/get/${blog.id}`, 'Error fetching blog tags');
                setBlogTags(data);
            } catch (err) {
                setError(err.message);
            }
        }
    }, [blog, fetchData]);

    const fetchBlogLikes = useCallback(async () => {
        if (blog) {
            const data = await fetchData(`${API_BASE_URL}/api/v1/like-blog/get/${blog.id}`, 'Error fetching blog likes');
            setBlogLikes(data);
        }
    }, [blog, fetchData]);

    const fetchFollowers = useCallback(async () => {
        const data = await fetchData(`${API_BASE_URL}/api/v1/follow/get/${username}`, 'Error fetching followers');
        setFollowers(data);
    }, [username, fetchData]);

    const fetchIsUserFollowing = useCallback(async () => {
        const data = await fetchData(`${API_BASE_URL}/api/v1/follow/get/${blog.user.username}/${emailLocal}`, 'Error fetching follow status');
        setIsFollowing(data);
    }, [blog, emailLocal, fetchData]);

    const fetchIsUserSaved = useCallback(async () => {
        const data = await fetchData(`${API_BASE_URL}/api/v1/save-blog/get/${emailLocal}/${blog.id}`, 'Error fetching saved status');
        setIsSaved(data);
    }, [blog, emailLocal, fetchData]);

    const fetchIsUserLiked = useCallback(async () => {
        const data = await fetchData(`${API_BASE_URL}/api/v1/like-blog/get/${emailLocal}/${blog.id}`, 'Error fetching liked status');
        if (data.blogId != null && data.email != null) {
            if (data.likeStatus) {
                setIsUserLiked(true);
            } else {
                setIsUserDisliked(true);
            }
        }
    }, [blog, emailLocal, fetchData]);

    useEffect(() => {
        if (emailLocal && blog) {
            setLoading(true);
            Promise.all([
                fetchIsUserFollowing(),
                fetchIsUserSaved(),
                fetchIsUserLiked()
            ]).finally(() => setLoading(false));
        }
    }, [emailLocal, blog, fetchIsUserFollowing, fetchIsUserSaved, fetchIsUserLiked]);

    useEffect(() => {
        setLoading(true);
        Promise.all([
            !blog.id ? fetchBlogDetail() : Promise.resolve(),
            fetchFollowers()
        ]).finally(() => setLoading(false));
    }, [fetchBlogDetail, fetchFollowers]);

    useEffect(() => {
        if (blog) {
            setLoading(true);
            Promise.all([
                fetchBlogTags(),
                fetchBlogLikes(),
                updateViewCount()
            ]).finally(() => setLoading(false));
        }
    }, [blog, fetchBlogTags, fetchBlogLikes, updateViewCount]);

    let DOMPurify;

    if (typeof window === 'undefined') {
        // Server-side
        const jsdomModule = eval("require('jsdom')");
        const { JSDOM } = jsdomModule;
        const window = new JSDOM('').window;
        DOMPurify = createDOMPurify(window);
    } else {
        // Client-side
        DOMPurify = createDOMPurify(window);
    }

    const sanitizeHTML = (html) => ({
        __html: DOMPurify.sanitize(html)
    });

    if (error) return <div>Error: {error}</div>;
    if (!blog) return <div>No blog found</div>;

    const relativeTime = getRelativeTime(blog.createdAt);

    const blogPostStructuredData = {
        "@context": "https://schema.org",
        "@type": "BlogPosting",
        "url": `https://w404.net/${username}/blog/${slug}`,
        "name": blog.title,
        "description": blog.shortDescription ? truncateDescription(blog.shortDescription) : truncateDescription(blog.title),
        "author": {
            "@type": "Person",
            "name": username
        },
        "datePublished": blog.createdAt,
        "dateModified": blog.updatedAt,
        "image": blog.imageUrl ? blog.imageUrl : "https://w404.net/assets/images/background.png",
        "publisher": {
            "@type": "Organization",
            "name": "W404",
            "logo": {
                "@type": "ImageObject",
                "url": "https://w404.net/assets/images/logo1.png"
            }
        }
    };

    return (
        <Fragment>
            <StructuredData imageUrl={blog.imageUrl ? blog.imageUrl : "https://w404.net/assets/images/background.png"}
                            data={blogPostStructuredData} title={blog.title} url={`https://w404.net/${username}/blog/${slug}`}
                            description={blog.shortDescription ? blog.shortDescription : blog.title}
                            item={{
                                author: blog.user?.username,
                                datePublished: blog.createdAt,
                                dateModified: blog.updatedAt}}
            />
            <MenuComponent/>
            <div className={"container"}>
                {loading ? <Spinner/> : (
                    <div className={"blog-detail"}>
                        {
                            emailLocal && blog.user.email === emailLocal && (
                                <div className="flex justify-end mb-2">
                                    <Link to={`/${blog.user.username}/edit/blog/${blog.slug}`}
                                          className="flex items-center text-[#A1A1AA] hover:border-b hover:border-[#F4F4F5] transform duration-300">
                                        <MdOutlineEditNote className="mr-1"/>
                                        Edit Blog
                                    </Link>
                                </div>
                            )
                        }
                        {
                            blog.imageUrl !== "" && (
                                <div className="relative h-[35vh] overflow-hidden rounded-t-2xl">
                                    <img
                                        src={blog.imageUrl}
                                        alt={blog.title}
                                        className="w-full object-cover object-top"
                                    />
                                    <div className="absolute inset-0 bg-black opacity-50"></div>
                                </div>
                            )
                        }


                        <h1 className="text-3xl font-bold mt-8 text-center mb-5">{blog.title}</h1>
                        <div className="play-detail-owner-render flex items-center mb-2 justify-between">
                            <div className="play-detail-owner-render-left flex items-center mb-4 justify-between">
                                <div className="flex items-center">
                                    <ImageComponent className={"mr-2 w-10 h-10"} image={blog.user.profileImageUrl}
                                                    alt={blog.user.displayName}/>
                                    <div>
                                        <Link to={`/user/${blog.user.username}`}>
                                            <p className="font-semibold">{blog.user.displayName}</p>
                                        </Link>
                                        <p className="text-sm text-gray-400">{followers} followers</p>
                                    </div>
                                </div>
                                {
                                    !emailLocal ? (
                                        <ShineBorder
                                            className={"ml-5 text-center px-4 py-2 capitalize opacity-50 cursor-not-allowed"}>Login
                                            to Follow</ShineBorder>
                                    ) : blog.user.email === emailLocal ? (
                                        <ShineBorder
                                            className={"ml-5 text-center px-4 py-2 capitalize opacity-50 cursor-not-allowed"}>Follow</ShineBorder>
                                    ) : isFollowing ? (
                                        <ShineBorder onClick={handleDeleteFollow}
                                                     className={"ml-5 text-center px-4 py-2 capitalize cursor-pointer"}>Following</ShineBorder>
                                    ) : (
                                        <ShineBorder onClick={handleFollow}
                                                     className={"ml-5 text-center px-4 py-2 capitalize cursor-pointer"}>Follow</ShineBorder>
                                    )
                                }

                            </div>

                            <div className="flex space-x-2 mb-4">
                                <div className={"bg-gray-700 rounded-full flex items-center"}>
                                    {
                                        isUserLiked ? (
                                            <button onClick={handleDeleteLikeBlog}
                                                    className="px-4 py-2 rounded-full flex items-center">
                                                <span><BiSolidLike/></span>
                                            </button>
                                        ) : (
                                            <button onClick={async () => handleLikeBlog(true)}
                                                    className="px-4 py-2 rounded-full flex items-center">
                                                <span><AiOutlineLike/></span>
                                            </button>
                                        )
                                    }
                                    {
                                        blogLikes < -14 ? (
                                            <span className={"text-red-500"}>x</span>) : (blogLikes === 0 ? (
                                            <span>-</span>) : (<span>{blogLikes}</span>))
                                    }
                                    {
                                        isUserDisliked ? (
                                            <button onClick={handleDeleteLikeBlog}
                                                    className=" px-4 py-2 rounded-full flex items-center">
                                                <span><BiSolidDislike/></span>
                                            </button>
                                        ) : (
                                            <button onClick={async () => handleLikeBlog(false)}
                                                    className=" px-4 py-2 rounded-full flex items-center">
                                                <span><AiOutlineDislike/></span>
                                            </button>
                                        )
                                    }

                                </div>
                                {
                                    isSaved ? (
                                        <button onClick={handleDeleteSavedBlog}
                                                className="bg-gray-700 px-4 py-2 rounded-full flex items-center">
                                            <CiBookmarkCheck
                                                className={"mr-2"}/>Saved
                                        </button>
                                    ) : (
                                        <button onClick={handleSaveBlog}
                                                className="bg-gray-700 px-4 py-2 rounded-full flex items-center">
                                            <CiBookmark
                                                className={"mr-2"}/>Save
                                        </button>
                                    )
                                }
                            </div>
                        </div>
                        <div className="mb-4 flex flex-col sm:flex-row sm:justify-between">
                            <div className="flex flex-wrap items-center mb-2 sm:mb-0">
                                {blogTags.map((tag, index) => (
                                    <a key={index}
                                          href={`/blogs/${tag.tagName}`}
                                          className={"mr-1 mb-1 text-[12px] rounded-xl px-2 bg-[#A1A1AA70] text-[#F4F4F580] hover:text-[#F4F4F5] hover:bg-[#A1A1AA] transform duration-300"}>
                                        {tag.tagName}
                                    </a>
                                ))}
                            </div>
                            <span
                                className="text-sm text-gray-600 flex items-center">{blog.viewCount} views - {relativeTime}</span>
                        </div>

                        {
                            blog.simple === true ? (
                                <div
                                    className="content-rich-text blog-content w-full break-words"
                                    dangerouslySetInnerHTML={sanitizeHTML(blog.body)}
                                />
                            ) : (
                                <iframe
                                    title="blog-body"
                                    srcDoc={blog.body}
                                    sandbox="allow-scripts allow-forms allow-modals"
                                    className="content-rich-text blog-content w-full break-words min-h-screen"
                                    onLoad={(e) => {
                                        if (e.target.contentDocument && e.target.contentDocument.body) {
                                            const height = Math.max(e.target.contentDocument.body.scrollHeight, window.innerHeight);
                                            e.target.style.height = `${height}px`;
                                        }
                                    }}
                                />
                            )
                        }


                        {
                            blog.comment === true && (
                                <CommentBlogDetail blogId={blog.id}/>
                            )
                        }

                    </div>
                )}

            </div>
            <FooterComponent/>
        </Fragment>
    )
}

export default BlogDetailComponent
