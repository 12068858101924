import React, { createContext, useState, useContext, useCallback, useEffect } from 'react';
import Cookies from 'js-cookie';

// Utility function to check if the code is running on the client
const isClient = () => typeof window !== 'undefined';

const CookieContext = createContext();

export const useCookies = () => useContext(CookieContext);

export const CookieProvider = ({ children, initialCookies = {} }) => {
    const [cookies, setCookies] = useState(initialCookies);

    useEffect(() => {
        if (isClient()) {
            setCookies({
                username: Cookies.get('username') || null,
                token: Cookies.get('token') || null,
                verified: Cookies.get('verified') || null,
                profileImageUrl: Cookies.get('profileImageUrl') || null,
                email: Cookies.get('email') || null,
                codeVerify: Cookies.get('codeVerify') || null,
                codePassword: Cookies.get('codePassword') || null,
            });
        }
    }, []);

    const setCookie = useCallback((name, value, options = {}) => {
        if (isClient()) {
            const expirationDate = new Date();
            expirationDate.setDate(expirationDate.getDate() + 90);

            const defaultOptions = {
                expires: expirationDate,
                path: '/',
                secure: true,
                sameSite: 'lax',
                ...options
            };

            Cookies.set(name, value, defaultOptions);
            setCookies(prevCookies => ({ ...prevCookies, [name]: value }));
        }
    }, []);

    const removeCookie = useCallback((name) => {
        if (isClient()) {
            Cookies.remove(name, { path: '/' });
            setCookies(prevCookies => {
                const newCookies = { ...prevCookies };
                delete newCookies[name];
                return newCookies;
            });
        }
    }, []);

    return (
        <CookieContext.Provider value={{ cookies, setCookie, removeCookie }}>
            {children}
        </CookieContext.Provider>
    );
};
